<template>
  <Layout :tituloPagina="`Sistema | Usuarios | ${modo=='nuevo' ? 'Nuevo' : 'Edición de'} usuario`">
    <div class="row">
      <div class="col-xxl-3">
        <div class="user-sidebar">
          <div class="card">
            <div class="card-body p-0">
              <div class="user-profile-img">
                <img
                  src="@/assets/images/pattern-bg.jpg"
                  class="profile-img profile-foreground-img rounded-top"
                  style="height: 120px"
                />
                <div class="overlay-content rounded-top">
                  <div>
                    <div class="user-nav p-3">
                      <div class="d-flex justify-content-end">
                        <div class="dropdown">
                          <a
                            class="dropdown-toggle"
                            href="javascript: void(0);"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <eva-icon
                              name="more-horizontal-outline"
                              data-eva-width="20"
                              data-eva-height="20"
                              class="fill-white"
                            ></eva-icon>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <button class="dropdown-item" @click="seleccionarFoto()">
                                <eva-icon name="repeat" class="text-primary"></eva-icon>
                                {{foto == null ? 'Seleccionar ' : 'Cambiar '}} imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="eliminarFoto()">
                                <eva-icon name="trash-2-outline" class="text-danger"></eva-icon>
                                Eliminar imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="visibleImagen = true">
                                <eva-icon name="eye-outline" class="text-info"></eva-icon>
                                Ver imagen completa
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-n5 position-relative">
                <div class="text-center">
                  <div v-show="foto == null" class="auth-logo">
                    <img
                      :src="usuarioicon"
                      alt="Foto del usuario"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-dark"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                    <img
                      :src="usuarioiconblanco"
                      alt="Foto del usuario"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-light"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                  </div>
                  <div v-if="foto != null">
                    <img 
                      :src="foto" 
                      class="avatar-xl rounded-circle img-thumbnail cursor" 
                      alt="Foto del usuario" 
                      title="Ver imagen completa"
                      @click="visibleImagen = true"
                    />
                    <vue-easy-lightbox
                      :visible="visibleImagen"
                      :imgs="foto_visor"
                      @hide="visibleImagen = false"
                    ></vue-easy-lightbox>
                  </div>

                  <div class="mt-3">
                    <h5 class="mb-1">
                      {{ usuario.nombre != '' ? usuario.nombre : 'Nombre(s)' }} 
                      {{ usuario.apellido_paterno != '' ? usuario.apellido_paterno : 'Apellido' }} 
                      {{ usuario.apellido_materno != '' ? usuario.apellido_materno : 'Apellido' }}
                    </h5>
                  </div>
                  <div class="mt-4"></div>
                </div>
              </div>
              <div class="p-3 mt-3"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-9">
        <div class="card">
          <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#sistema"
                role="tab"
              >
                <i class="bx bx-cog font-size-20"></i>
                <span class="d-none d-sm-block">Sistema</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#datos"
                role="tab"
                v-if="modo != 'nuevo'"
              >
                <i class="bx bx-user-circle font-size-20"></i>
                <span class="d-none d-sm-block">Datos personales</span>
              </a>
            </li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
          </ul>

          <div class="tab-content p-4" style="min-height: 300px;">
            <div class="tab-pane active" id="sistema" role="tabpanel">
              <h5>Claves de acceso</h5>

              <div class="row pt-0">
                <div class="col-md-3" v-if="modo=='edicion'">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="idUsuario"
                      placeholder="#ID"
                      readonly
                      required
                      v-model="usuario.id"
                    />
                    <label for="idUsuario">ID</label>
                    <div class="form-floating-icon">
                      <eva-icon name="hash-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="identidad"
                      ref="identidad"
                      placeholder="Identidad"
                      required
                      v-model="usuario.identidad"
                    />
                    <label for="identidad">Identidad</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                    <input
                      :type="type"
                      class="form-control pe-5"
                      id="clave"
                      ref="clave"
                      placeholder="Clave"
                      required=""
                      v-model="usuario.clave"
                    />
                    <button 
                      type="button"
                      class="btn btn-link position-absolute h-100 end-0 top-0"
                      id="clave-addon"
                      @click="type == 'text' ? type ='password': type ='text'"
                    >
                      <i class="mdi mdi-eye-outline font-size-18 text-muted"></i>
                    </button>
                    <label for="password-input">Clave</label>
                    <div class="form-floating-icon">
                      <eva-icon name="lock-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
              
              <br v-if="modo == 'nuevo'">

              <div v-if="modo == 'nuevo'">
                <h5>Datos personales</h5>
              
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="nombre"
                        name="nombre"
                        ref="nombre"
                        placeholder="Nombre del usuario"
                        required
                        v-model="usuario.nombre"
                      />
                      <label for="nombre">Nombre del usuario</label>
                      <div class="form-floating-icon">
                        <eva-icon name="people-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="apellido_paterno"
                        name="apellido_paterno"
                        placeholder="Apellido paterno del usuario"
                        required
                        v-model="usuario.apellido_paterno"
                      />
                      <label for="apellido_paterno">Apellido paterno</label>
                      <div class="form-floating-icon">
                        <eva-icon name="people-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="apellido_materno"
                        name="apellido_materno"
                        placeholder="Apellido paterno del usuario"
                        required
                        v-model="usuario.apellido_materno"
                      />
                      <label for="apellido_materno">Apellido materno</label>
                      <div class="form-floating-icon">
                        <eva-icon name="people-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <h5>Contacto de usuario</h5>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="telefono"
                        name="telefono"
                        placeholder="telefono del usuario"
                        required
                        v-model="usuario.telefono"
                      />
                      <label for="telefono">Teléfono o celular</label>
                      <div class="form-floating-icon">
                        <eva-icon name="phone-call-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="correo"
                        name="correo"
                        placeholder="correo del usuario"
                        required
                        v-model="usuario.correo"
                      />
                      <label for="correo">Correo electrónico</label>
                      <div class="form-floating-icon">
                        <eva-icon name="at-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <h5>
                    Roles de usuario
                    <button class="btn btn-soft-dark btn-sm"
                      ref="mostrarRoles"
                      @click="mostrarMdlRoles()">
                      <i class="mdi mdi-plus-thick"></i>
                      Asignar
                    </button>
                  </h5>
                  <span v-for="rol in usuario.roles" :key="rol.id"
                    class="badge bg-success p-2 m-1 font-size-12 bg-gradient fw-semibold rounded" style="display: inline-block;">
                    {{rol.nombre}}
                    <a href="#" onclick="return false"
                      @click="quitarRol(rol.id)">
                      <i class="mdi mdi-close"></i>
                    </a>
                  </span>
                </div>
                <!-- Estado del usuario -->
                <div class="col-lg-6 col-md-6 col-sm-6" v-show="modo=='edicion'">
                  <h5>Estado del usuario</h5>
                  <div class="form-floating form-floating-custom mb-4">
                    <select
                      class="form-select"
                      id="activo"
                      v-model="usuario.activo"
                    >
                      <option :value="1">Activo</option>
                      <option :value="0">Inactivo</option>
                    </select>
                    <label for="activo">Estado del usuario</label>
                    <div class="form-floating-icon">
                      <eva-icon :name="usuario.activo ? 'person-done-outline' : 'person-remove-outline'"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="datos" role="tabpanel" v-if="modo != 'nuevo'">
              <h5>Datos personales</h5>
              
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="nombre"
                      name="nombre"
                      ref="nombre"
                      placeholder="Nombre del usuario"
                      required
                      v-model="usuario.nombre"
                    />
                    <label for="nombre">Nombre del usuario</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="apellido_paterno"
                      name="apellido_paterno"
                      placeholder="Apellido paterno del usuario"
                      required
                      v-model="usuario.apellido_paterno"
                    />
                    <label for="apellido_paterno">Apellido paterno</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="apellido_materno"
                      name="apellido_materno"
                      placeholder="Apellido paterno del usuario"
                      required
                      v-model="usuario.apellido_materno"
                    />
                    <label for="apellido_materno">Apellido materno</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <h5>Contacto de usuario</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="telefono"
                      name="telefono"
                      placeholder="telefono del usuario"
                      required
                      v-model="usuario.telefono"
                    />
                    <label for="telefono">Teléfono o celular</label>
                    <div class="form-floating-icon">
                      <eva-icon name="phone-call-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="correo"
                      name="correo"
                      placeholder="correo del usuario"
                      required
                      v-model="usuario.correo"
                    />
                    <label for="correo">Correo electrónico</label>
                    <div class="form-floating-icon">
                      <eva-icon name="at-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right pb-4">
          <div class="btn-group">
            <button class="btn btn-secondary"
              @click="cerrar()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="modo=='nuevo'?guardar():actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{modo=='nuevo'?'Guardar': 'Actualizar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <mdl-roles ref="mdlRoles" @rol:seleccionado="asignarRol($event)"></mdl-roles>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import UsuarioSrv from '@/services/UsuarioSrv.js'
import usuarioicon from '@/assets/img/logoArgus.png'
import usuarioiconblanco from '@/assets/img/logoArgusBlanco.png'
import MdlRoles from './MdlRoles.vue'
import VueEasyLightbox from "vue-easy-lightbox"
import API from '@/API.js'
export default {
  name: 'EdicionUsuario',
  components: {
    Layout,
    MdlRoles,
    VueEasyLightbox,
  },
  props: ['idUsuario'],
  data() {
    return {
      modo: 'nuevo',
      tk: localStorage.getItem('argusblack.token'),
      rnd: Math.random(),
      API: API,
      baseUrl: window.location.origin,
      usuarioicon: usuarioicon,
      usuarioiconblanco: usuarioiconblanco,
      foto_visor: null,
      idFoto: null,
      usuario: {
        id: null,
        identidad: '',
        clave: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefono: '',
        correo: '',
        id_foto: null,
        activo: 1,
        roles: [],
        foto: null
      },
      foto: null,
      roles: [],
      rol: {
        id: null,
        nombre: ''
      },
      bandera_spinner: false,
      visibleImagen: false,
      type:'password',
    }
  },

  created: function() {
    var self = this

    // Carga del modo
    if (this.$route.path.indexOf('nuevo') == -1) this.modo = 'edicion'

    // Carga Usuario
    if (this.modo == 'edicion') self.cargarUsuario()
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      // Verificación de datos correctos
      if (!self.verificarDatosCorrectos()) return

      let usuario = Object.assign({}, self.usuario)
      usuario.roles = usuario.roles.map(r => r.id)

      UsuarioSrv.actualizar(usuario).then(response => {
        iu.msg.success('Se actualizó correctamente')
        if (self.$store.state.todo.usuario.id == usuario.id) {
          iu.bus.emit('fotoUsuarioActualizada')
        }

        if (
          self.usuario.id == self.$store.state.todo.usuario.id &&
          (self.usuario.identidad != self.$store.state.todo.usuario.identidad ||
          self.usuario.clave != self.$store.state.todo.usuario.clave)
        ) {
          iu.msg.warning('Es necesario volver a iniciar la sesión')
          localStorage.removeItem('argusblack.token')
          self.$router.push({ name: 'login' })
        } else {
          self.cargarUsuario()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    asignarRol(rol) {
      let res = this.usuario.roles.find(r => r.id == rol.id)
      
      // Si está asignado el rol, se evita volver a asignarlo
      if(res != undefined) return;

      this.usuario.roles.push(rol)
    },

    cargarUsuario: function() {
      var self = this

      UsuarioSrv.usuarioJSON(this.idUsuario).then(response => {
        let usuario = response.data
        self.rnd = Math.random()
        self.usuario = usuario

        if(self.usuario.id_foto != null) {
          self.foto = self.API + '/usuarios/' + self.usuario.id + '/foto?tipo_foto=256x256&_tk=' + self.tk + '&rnd=' + Math.random()
          self.foto_visor = self.API + '/usuarios/' + self.usuario.id + '/foto?tipo_foto=original&_tk=' + self.tk + '&rnd=' + Math.random()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'Usuario no encontrado'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    eliminarFoto: function() {
      var self = this
      self.foto = null
      self.foto_visor = null
      self.usuario.eliminar_foto_antigua = 1
    },

    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) {
        self.bandera_spinner = false
        return
      }
      
      let usuario = Object.assign({}, self.usuario)
      delete usuario.id
      delete usuario.id_foto
      usuario.roles = usuario.roles.map(r => r.id)
      
      UsuarioSrv.guardar(usuario).then(response => {
        iu.msg.success('Nuevo usuario guardado')
        var id = response.data
        
        self.modo = 'edicion'
        self.usuario.id = id

        self.$router.replace({
          name: 'edicionUsuario',
          params: {
            idUsuario: id,
            nuevoUsuario: true
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar, intentalo de nuevo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    mostrarMdlRoles() {
      this.$refs.mdlRoles.mostrar()
    },

    quitarRol(idRol) {
      if(this.usuario.roles.length == 1) {
        iu.msg.warning('No se puede eliminar el rol, porque es el único que tiene asignado')
        return
      }
      
      // Si el usuario tiene más de un rol, se procede a eliminar el rol
      let index = this.usuario.roles.findIndex(r => r.id == idRol)
      this.usuario.roles.splice(index, 1)
    },

    seleccionarFoto: function() {
      let self = this
      var inpFoto = document.createElement("input")
      inpFoto.setAttribute('type', 'file')
      var reader = new FileReader()

      inpFoto.click()
      
      reader.onload = function(e) {
        self.foto = this.result
        self.foto_visor = this.result
      }

      inpFoto.addEventListener('change', function(){
        self.usuario.foto = inpFoto.files[0]
        self.usuario.eliminar_foto_antigua = true
        reader.readAsDataURL(self.usuario.foto)
      })
    },

    verificarDatosCorrectos: function() {
      var self = this

      if (self.usuario.identidad == '') {
        iu.msg.warning('Es necesaria una identidad')
        self.$refs.identidad.select()
        self.bandera_spinner = false
        return false
      }

      if (self.usuario.clave == '' || self.usuario.clave == null) {
        iu.msg.warning('Es necesaria una clave')
        self.$refs.clave.select()
        self.bandera_spinner = false
        return false
      }

      if (self.usuario.nombre == '') {
        iu.msg.warning('Es necesario un nombre')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return false
      }

      if (self.usuario.roles.length == 0) {
        iu.msg.warning('Es necesario asignar al menos un rol')
        self.$refs.mostrarRoles.click()
        self.bandera_spinner = false
        return false
      }

      return true
    }
  },
}
</script>

<style scoped>
.avatar-xl {
  height: 10rem;
  width: 10rem;
}
.cursor{
  cursor: pointer;
}
</style>